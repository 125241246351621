import { toast } from './toast.js';

let nsw;

function updateSWtoast(sw) {
    console.log(sw);
    toast().show('Hay una nueva versión disponible, hacé click acá para actualizar.', 'warning', true, function () {
        sw.postMessage('skipWaiting');
        //window.location.replace(window.location.href);
    });
}

if ('serviceWorker' in navigator) {

    window.addEventListener('load', async () => {
        let swUrl = new URL('../../service-worker.js', import.meta.url);
        navigator.serviceWorker
            .register(swUrl.origin + swUrl.pathname, { type: 'module' })
            .then((registration) => {

                if (registration.waiting) {
                    console.log('waiting');
                    updateSWtoast(registration.waiting);
                }

                registration.addEventListener('updatefound', () => {
                    nsw = registration.installing;

                    nsw.addEventListener('statechange', () => {
                        console.log(nsw.state);

                        switch (nsw.state) {
                            case 'installed':
                                if (navigator.serviceWorker.controller) {
                                    //updateSWtoast(registration.waiting);
                                }
                                break;
                            case 'activated':
                                //window.location.replace(window.location.href);
                                break;
                        }
                    });

                });

            })
            .catch((err) => console.error('Service Worker Error', err));

        navigator.serviceWorker.addEventListener('controllerchange', () => {
            window.location.replace(window.location.href);
        });

    });
}