var toast = function () {
    return {
        show: function (
            html = '',
            type = 'success',
            permanent = false, // or Delay
            click = 'exit',
            classes = false
        ) {

            // Remove Existing Toats
            let oldToasts = document.querySelectorAll('.app-toast');
            if (oldToasts) {
                oldToasts.forEach(function (element) {
                    element.classList.add('animate__flipOutX');
                    element.addEventListener('animationend', function () {
                        this.remove();
                    });
                })
            }
            let toastNode = document.querySelector('#app-toast');
            let itemToast = toastNode.content.cloneNode(true);
            let toastId = oldToasts.length + 1;

            itemToast.firstElementChild.classList.add('bg-' + type);
            itemToast.firstElementChild.innerHTML = html;
            itemToast.firstElementChild.setAttribute('id', 'toast-' + toastId);

            let words = html.split(' ').length * 250;
            if (words < 4000) words = 4000;
            let time = Number.isInteger(permanent) ? permanent : words;

            if (classes) itemToast.firstElementChild.classList.add(...classes);

            if (click) {
                if (click === "exit") {
                    itemToast.firstElementChild
                        .addEventListener('click', function (e) {
                            e.stopPropagation();
                            this
                                .classList.add('animate__flipOutX')
                                .addEventListener('animationend', function () {
                                    this.remove();
                                });
                        }, true);
                } else {
                    itemToast.firstElementChild
                        .addEventListener('click', function (e) {
                            e.stopPropagation();
                            click();
                        }, true);
                }
            }


            if (permanent === false || Number.isInteger(permanent)) {
                setTimeout(() => {
                    let createdToast = document.querySelector('#toast-' + toastId);
                        if (createdToast) {
                        createdToast.classList.add('animate__flipOutX');
                        createdToast.addEventListener('animationend', function () {
                            this.remove();
                        });
                    }
                }, time);
            }

            document.body.appendChild(itemToast);
        },
    };
};

export { toast }