import * as bootstrap from 'bootstrap';
import { settings } from './settings.js';
import { db } from './db.js';
import { forms } from './forms.js';
import { toast } from './toast.js';

import localforage from 'localforage/dist/localforage.min.js';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

import mixitup from 'mixitup';

import 'material-icons/iconfont/material-icons.css';

var grid;
var page = function () {
    let filtersCollection = {};
    let productsCollection = {};
    let coeficientsCollection = {};
    let stockCollection = {};

    // Templates
    let appModal = document.querySelector('#app-modal');
    let appForm = document.querySelector('#app-form');
    let appClient = document.querySelector('#app-client');
    let itemList = document.querySelector('#app-list');
    let itemNode = document.querySelector('#app-list-item');
    let itemPriceNode = document.querySelector('#app-list-price');

    // Selectors
    let filterSearch = document.querySelector('#app-search');
    let filterSearchClean = document.querySelector('#app-search-clean');
    let filterList = document.querySelector('#app-filter');
    let filtersWrap = document.querySelector('#app-filters');
    let filterOptionsList = document.querySelector('#app-filter-options');
    let filterStockList = document.querySelector('#app-filter-stock');
    let filterNode = document.querySelector('#app-filter-item');
    let listPagination = document.querySelector('#app-pagination');

    // Vars
    let filterChecked = false;
    let optionsChecked = [];
    let stocksChecked = [];
    let pricesChecked = [];
    let productSharePrices = {};

    // Load Vars
    localforage.getItem('Setup Precios').then(function (data) {
        pricesChecked = data || [];
    });

    // Utilities
    let formatCurrency = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS'
    });

    const deposits = ['GP', 'P', 'SR'];
    const f = {
        type: 'TIPO',
        rim: 'ARO',
        code: 'CODIGO',
        size: 'MEDIDA',
        title: 'DESCRIPCION',
        price: 'Contado',
        condition: 'CONDICION',
        brand: 'MARCA',
        order: 'ORDEN',
        visible: 'MOSTRAR',
        showStock: 'STOCKVISIBLE',
        image: 'IMAGENES',
        coef: 'Coeficiente',
        coefTerm: 'Plazo',
        date: 'FECHA',
        P: 'PIGUE',
        GP: 'GRAL PICO',
        SR: 'SANTA ROSA',
    };

    let implodeClass = (sufix, prefix) => {
        return [(prefix ? (prefix.startsWith('.') ? '' : '.' + prefix) : ''), (sufix.startsWith('.') ? sufix.slice(1) : sufix)].join('.');
    }

    return {

        load: function (page, currentUser, refresh = false) {
            let that = this;
            // Clean Up
            filterList.innerHTML = '';
            filterOptionsList.innerHTML = '';
            filterStockList.innerHTML = '';
            listPagination.innerHTML = '';
            itemList.innerHTML = '<br><br>';

            document.querySelector('body').classList.add('app-loading'); // Add Loading
            document.querySelector('.app-filter-options-wrap').classList.add('d-none'); // Hide on Init
            filtersWrap.classList.remove('d-none'); // Reset Visibility
            document.body.classList.remove('app-block');


            // Collections
            const coeficientsParsed = new Promise((resolve, reject) => {
                db().fetchPage('Coeficientes', refresh)
                    .then((data) => {
                        if ('type' in data && data.type === 'error') reject(data);

                        coeficientsCollection = data;
                        resolve(true);
                    });
            });

            const stockParsed = new Promise((resolve, reject) => {
                db().fetchPage('Stock', refresh)
                    .then((data) => {
                        if ('type' in data && data.type === 'error') reject(data);

                        if (data.length > 0) {
                            data.forEach(function (row, i) {
                                stockCollection[row[f.code]] = row;

                                if (i + 1 === Object.values(data).length) resolve(true);
                            });
                        } else {
                            resolve(true);
                        }
                    });
            });

            // Wait for Stock
            coeficientsParsed
                .then(stockParsed)
                .then(function () {
                    db().fetchPage(page, refresh)
                        .then((data) => {
                            new Promise((resolve, reject) => {
                                if ('type' in data && data.type === 'error') reject(data);

                                if (data.length > 0) {
                                    // Parse
                                    data.forEach(function (row, i) {
                                        if (row && Object.values(row)[0] != '') {

                                            // Stock
                                            if (row[f.code] in stockCollection) {
                                                row['stock'] = stockCollection[row[f.code]];
                                            }

                                            // Filter A
                                            const filterValueA = that.cleanUp(row[f.type], f.type);
                                            if (filterValueA) {
                                                if (!filtersCollection.hasOwnProperty(filterValueA)) {
                                                    let filterRow = {
                                                        value: row[f.type],
                                                        options: {}
                                                    };
                                                    filtersCollection[filterValueA] = filterRow;
                                                }
                                            }

                                            // Filter B
                                            const filterValueB = that.cleanUp(row[f.rim], f.rim);
                                            if (filterValueB) {
                                                if (!filtersCollection[filterValueA].options.hasOwnProperty(filterValueB)) {
                                                    filtersCollection[filterValueA].options[filterValueB] = row[f.rim];
                                                }
                                            }

                                            productsCollection[row[f.order] || i] = row;
                                        }

                                        if (i + 1 === Object.values(data).length) {
                                            resolve(true);
                                        }
                                    });
                                }
                            });
                        })
                        .then(() => {
                            let productsCollectionEntries = Object.entries(productsCollection);
                            itemList.innerHTML = '';

                            if (productsCollectionEntries.length > 0) {
                                // Products
                                let p = 0;
                                for (const [i, row] of productsCollectionEntries) {
                                    p++;

                                    if (f.visible in row && row[f.visible] === 0) continue;

                                    let itemProduct = itemNode.content.cloneNode(true);
                                    let titleProduct = itemProduct.querySelector('.app-list-title');
                                    let bodyProduct = itemProduct.querySelector('.app-list-body');
                                    let checkboxProduct = itemProduct.querySelector('.form-check-input');

                                    const classes = [that.cleanUp(row[f.type], f.type), that.cleanUp(row[f.rim], f.rim)];
                                    itemProduct.firstElementChild.classList.add(...classes);
                                    titleProduct.innerHTML = row[f.title];
                                    bodyProduct.innerHTML = '';

                                    // Share
                                    checkboxProduct.value = i;

                                    // Stock
                                    if (row.stock) {
                                        let stock = row.stock;
                                        deposits.forEach(function (name) {
                                            if (stock[name] > 0)
                                                itemProduct.firstElementChild.classList.add(that.cleanUp(name, 'STOCK'));

                                            const stockLabel = currentUser && currentUser.role === 'VENDEDOR' ? stock[name] : stock[name + '_CLIENTE'];
                                            bodyProduct.innerHTML += `<div class="d-flex justify-content-between flex-wrap"><b class="me-1">${f[name]}</b>${stockLabel}</div>`;
                                        });
                                    }

                                    // Product Modal
                                    itemProduct.firstElementChild.addEventListener('click', function (e) {
                                        if (e.target.classList.contains('no-modal')) return false;

                                        const modal = new bootstrap.Modal(appModal);
                                        let producCover = appModal.querySelector('.product-cover');
                                        let productTitle = appModal.querySelector('.product-title');
                                        let productDetails = appModal.querySelector('.product-details');
                                        let productDiscount = appModal.querySelector('#product-discount');
                                        let productDiscountSlider = appModal.querySelector('#product-discount-slider');
                                        let productPrices = appModal.querySelector('.product-prices');
                                        let productNotes = appModal.querySelector('.product-notes');
                                        let productStock = appModal.querySelector('.product-stock');
                                        let productStockNotes = appModal.querySelector('.product-stock-notes');
                                        let productPrice = parseFloat(row[f.price]);
                                        let productShare = appModal.querySelector('.btn-share');

                                        // Details
                                        producCover.innerHTML = '';
                                        let cover = new Image();
                                        const productImage = row[f.image];
                                        cover.onload = function () { producCover.appendChild(cover); }
                                        cover.src = 'product/' + productImage;
                                        cover.classList.add('w-100', 'rounded-top');

                                        productTitle.innerHTML = row[f.title];
                                        productDetails.innerHTML = `
                                            <div class="d-flex flex-column">
                                                <div><b class="me-1">CODIGO</b> ${row[f.code]}</div>
                                                <div><b class="me-1">TIPO</b> ${row[f.type]}</div>
                                                <div><b class="me-1">MARCA</b> ${row[f.brand]}</div>
                                                <div><b class="me-1">MEDIDA</b> ${row[f.size]}</div>
                                                <div><b class="me-1">ARO</b> ${row[f.rim]}</div>
                                            </div>
                                        `;

                                        // Stock
                                        productStock.innerHTML = '';
                                        productStockNotes.innerHTML = '';
                                        if (row[f.code] in stockCollection) {
                                            let stock = stockCollection[row[f.code]];
                                            deposits.forEach(function (name) {
                                                const stockLabel = currentUser && currentUser.role === 'VENDEDOR' ? stock[name] : stock[name + '_CLIENTE'];
                                                productStock.innerHTML += `<div class="d-flex justify-content-between align-items-center flex-wrap gap-1 lh-1 small"><b class="">${f[name]}</b>${stockLabel}</div>`;
                                            });
                                            if (stock[f.date]) productStockNotes.innerHTML = `Stock al ${moment(stock[f.date]).format('DD-MM-YY HH:mm')}`;
                                        }

                                        // Discount
                                        productDiscountSlider.addEventListener('input', function () {
                                            productDiscount.value = this.value;
                                            that.loadModalPrices(productPrices);
                                        });
                                        productDiscount.setAttribute('data-price', productPrice);
                                        productDiscount.addEventListener('input', function () {
                                            if (isNaN(this.value) || this.value < 0) this.value = 0;
                                            if (this.value > 20) this.value = 20;
                                            productDiscountSlider.value = this.value;
                                            that.loadModalPrices(productPrices);
                                        });

                                        // Prices
                                        productSharePrices = {};
                                        if (row[f.condition]) productNotes.innerHTML = `Condicion ${row[f.condition]}`;

                                        coeficientsParsed
                                            .then(that.loadModalPrices(productPrices))
                                            .then(() => {
                                                productShare.addEventListener('click', function () {
                                                    // Share
                                                    let shareObject = [];
                                                    shareObject.push(`*PRODUCTO*\t${row[f.title]}`);
                                                    shareObject.push(`*MARCA*\t\t${row[f.brand]}`);
                                                    shareObject.push(`*PRECIO*\t\t_Condición ${row[f.condition]}_`);
                                                    if (Object.keys(productSharePrices).length > 0) {
                                                        Object.values(productSharePrices).forEach(function (price, i) {
                                                            shareObject.push(price);
                                                        });

                                                        if (productDiscount.value > 0)
                                                            shareObject.push(`\n_Los precios incluyen un descuento del ${productDiscount.value}%_`);

                                                        shareObject.push(`\n_Presupuestado el ${moment().format('DD-MM-YYYY')}_`);
                                                    }

                                                    var waWindow = window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent(shareObject.join('\n')), '_system', 'location=yes');
                                                    setTimeout(() => {
                                                        appModal.focus();
                                                        if (waWindow) waWindow.close();
                                                    }, 3000);

                                                }, true);
                                            })
                                            .then(() => modal.show());

                                    }, true);

                                    itemList.appendChild(itemProduct);
                                }
                            }
                        })
                        .then(() => {
                            // Set Pagination 
                            that.updatePagination(itemList.children.length);

                            // Build Grid
                            if (grid) {
                                grid.forceRefresh();
                            } else {
                                grid = mixitup('.app-list', {
                                    selectors: {
                                        target: '.app-list-item'
                                    },
                                    controls: {
                                        enable: false
                                    },
                                    callbacks: {
                                        onMixEnd: function (state, futureState) {
                                            that.updatePagination(state.totalShow);
                                        }
                                    },
                                    animation: {
                                        enable: false
                                    }
                                });
                            }

                            // Filters                            
                            let filtersEntries = Object.entries(filtersCollection);
                            filtersEntries.sort(function (a, b) {
                                if (a[1].value < b[1].value) return -1;
                                if (a[1].value > b[1].value) return 1;
                                return 0;
                            });

                            for (const [classes, row] of filtersEntries) {
                                let itemFilter = filterNode.content.cloneNode(true);
                                let titleFilter = itemFilter.querySelector('.app-filter-title');

                                titleFilter.setAttribute('filter', classes);
                                titleFilter.innerHTML = row.value;
                                titleFilter.classList.add('border-light');

                                // Click on Filter
                                titleFilter.addEventListener('click', function (e) {
                                    const activeFilterClasslist = this.classList;
                                    const filterIsActive = activeFilterClasslist.contains('filter-active');

                                    // Clean Classes
                                    let prevActiveFilter = document.querySelector('.app-filter .filter-active');
                                    if (prevActiveFilter) prevActiveFilter.classList.remove('filter-active');
                                    optionsChecked = [];

                                    if (filterIsActive) {
                                        filterOptionsList.innerHTML = '';
                                        document.querySelector('.app-filter-options-wrap').classList.add('d-none');

                                        activeFilterClasslist.remove('filter-active');
                                        filterChecked = false;

                                        that.filterProducts();
                                    } else {
                                        const filter = this.getAttribute('filter');
                                        activeFilterClasslist.add('filter-active');
                                        filterChecked = filter;

                                        that.filterProducts();

                                        // Options
                                        const options = filtersCollection[filter].options;
                                        let optionsEntries = Object.entries(options);
                                        optionsEntries.sort((a, b) => a[1] - b[1]);

                                        filterOptionsList.innerHTML = '';
                                        for (const [classes, value] of optionsEntries) {
                                            let itemOption = filterNode.content.cloneNode(true);
                                            let titleOption = itemOption.querySelector('.app-filter-title');

                                            itemOption.firstElementChild.classList.replace('col-4', 'col-2');
                                            titleOption.setAttribute('filter', classes);
                                            titleOption.innerHTML = value;
                                            titleOption.classList.remove('py-md-3');
                                            titleOption.classList.add('border-dark-subtle');


                                            // Click on Option
                                            titleOption.addEventListener('click', function (e) {
                                                const option = this.getAttribute('filter');
                                                const activeOption = this.classList.toggle('filter-active');

                                                if (activeOption) {
                                                    optionsChecked.push(option);
                                                } else {
                                                    const i = optionsChecked.indexOf(option);
                                                    if (i > -1) optionsChecked.splice(i, 1);
                                                }

                                                that.filterProducts();
                                            }, true);

                                            filterOptionsList.appendChild(itemOption);
                                        }
                                        document.querySelector('.app-filter-options-wrap').classList.remove('d-none');

                                    }

                                }, true);

                                filterList.appendChild(itemFilter);
                            }

                            // Stock Filter
                            filterStockList.innerHTML = '';
                            deposits.forEach(function (name) {
                                let itemStock = filterNode.content.cloneNode(true);
                                let titleStock = itemStock.querySelector('.app-filter-title');

                                itemStock.firstElementChild.classList.replace('col-6', 'col-4');

                                titleStock.setAttribute('filter', that.cleanUp(name, 'STOCK'));
                                titleStock.innerHTML = f[name];

                                // Click on Stock
                                titleStock.addEventListener('click', function (e) {
                                    const option = this.getAttribute('filter');
                                    const activeOption = this.classList.toggle('filter-active');

                                    if (activeOption) {
                                        stocksChecked.push(option);
                                    } else {
                                        const i = stocksChecked.indexOf(option);
                                        if (i > -1) stocksChecked.splice(i, 1);
                                    }

                                    that.filterProducts();
                                }, true);

                                filterStockList.appendChild(itemStock);
                            });

                            // Search
                            filterSearch.addEventListener('keyup', function (e) {
                                if (e.target.value.length > 2 || e.key === 'Backspace') {
                                    // Clean Filters
                                    for (var filter of document.querySelectorAll('.filter-active')) {
                                        filter.classList.remove('filter-active');
                                    }
                                    filterChecked = false;
                                    optionsChecked = [];
                                    stocksChecked = [];
                                    filterOptionsList.innerHTML = '';
                                    document.querySelector('.app-filter-options-wrap').classList.add('d-none');

                                    grid.filter('all');

                                    let query = e.target.value.toUpperCase();
                                    if (query) {
                                        var collection = Array.from(itemList.querySelectorAll('.app-list-item'));
                                        var itemsFiltered = collection.filter(function (item) {
                                            const title = item.querySelector('.app-list-title').innerHTML;
                                            return title.search(query) > -1;
                                        });
                                        grid.filter(itemsFiltered);
                                    }
                                }
                            });

                            filterSearch.addEventListener('input', function (e) {
                                if (e.target.value === '') grid.filter('all');
                            });

                            filterSearchClean.addEventListener('click', function (e) {
                                const searchValue = filterSearch.children[0].value;
                                if (searchValue.length > 0) {
                                    filterSearch.children[0].value = '';
                                    grid.filter('all');
                                }
                            });


                        })
                        .then(() => {
                            document.querySelector('body').classList.remove('app-loading');
                        });
                })
                .catch(function (response) {
                    if ('type' in response && response.type === 'error') {
                        toast().show(response.message, 'warning');
                        db().logOut();
                        return false;
                    }
                })

        },
        loadModalPrices: function (productPrices) {
            new Promise((resolve, reject) => {
                let productDiscountInput = document.querySelector('#product-discount');
                let productDiscount = productDiscountInput.value === '' || isNaN(productDiscountInput.value) ? 0 : parseFloat(productDiscountInput.value);
                let productPrice = productDiscountInput.getAttribute('data-price');

                productPrices.innerHTML = '';
                const coeficientsLength = Object.entries(coeficientsCollection).length;
                if (coeficientsLength > 0) {
                    coeficientsCollection.forEach(function (coef, i) {
                        let c = parseFloat(coef[f.coef]) || 0;
                        if (c != 0) {
                            let itemPrice = itemPriceNode.content.cloneNode(true);
                            let inputPrice = itemPrice.querySelector('.form-check-input');
                            let labelPrice = itemPrice.querySelector('.form-check-label');
                            let term = itemPrice.querySelector('.price-term');
                            let total = itemPrice.querySelector('.price-total');
                            const termPrice = coef[f.coefTerm];
                            const totalPrice = formatCurrency.format((productPrice * c * (1 - productDiscount / 100)).toFixed(2));
                            const activePrice = pricesChecked.length > 0 ? pricesChecked.indexOf(termPrice) > -1 : false;

                            inputPrice.setAttribute('value', termPrice);
                            inputPrice.setAttribute('data-total', totalPrice);
                            inputPrice.setAttribute('data-i', i);
                            inputPrice.setAttribute('id', termPrice);
                            labelPrice.setAttribute('for', termPrice);
                            inputPrice.checked = activePrice;
                            total.innerHTML = totalPrice;
                            term.innerHTML = termPrice;

                            // Share
                            if (activePrice)
                                productSharePrices[i] = '• ' + termPrice + ': ' + totalPrice;

                            inputPrice.addEventListener('change', function (e) {
                                // Save Preference
                                const termOrder = e.target.getAttribute('data-i');
                                if (e.target.checked) {

                                    pricesChecked.push(e.target.value);

                                    // Share
                                    productSharePrices[termOrder] = '• ' + e.target.value + ': ' + this.getAttribute('data-total');
                                } else {
                                    const i = pricesChecked.indexOf(e.target.id);
                                    if (i > -1) pricesChecked.splice(i, 1);

                                    // Share
                                    delete productSharePrices[termOrder];
                                }

                                localforage.setItem('Setup Precios', pricesChecked);
                            }, true);

                            productPrices.append(itemPrice);
                        }

                        if (i++ === coeficientsLength) resolve(true);
                    });
                }
            });
        },
        filterProducts: function () {
            const optionsArray = optionsChecked.map(v => implodeClass(v, filterChecked));

            filterSearch.firstElementChild.value = '';
            if (stocksChecked.length > 0) {
                if (optionsArray.length > 0) {
                    if (stocksChecked.length > 1) {
                        let stockArray = [];
                        stocksChecked.forEach(function (stockChecked, i) {
                            const stockOptionArray = optionsArray.map(v => implodeClass(v, stockChecked));
                            stockArray = stockArray.concat(stockOptionArray);

                            if (i + 1 === stocksChecked.length) {
                                grid.filter(stockArray.join(','));
                            }
                        });
                    } else {
                        let stockArray = optionsArray.map(v => implodeClass(v, stocksChecked[0]));
                        grid.filter(stockArray.join(','));
                    }
                } else {
                    if (stocksChecked.length > 1) {
                        let stockArray = stocksChecked.map(v => implodeClass(v, filterChecked));
                        grid.filter(stockArray.join(','));
                    } else if (filterChecked) {
                        grid.filter('.' + filterChecked + '.' + stocksChecked[0]);
                    } else {
                        grid.filter('.' + stocksChecked[0]);
                    }
                }
            } else {
                // No Stock
                if (optionsArray.length > 0) {
                    grid.filter(optionsArray.join(','));
                } else if (filterChecked) {
                    grid.filter('.' + filterChecked);
                } else {
                    grid.filter('all');
                }
            }
        },
        updatePagination: function (total) {
            listPagination.innerHTML = `${total} producto${total != 1 ? 's' : ''} disponible${total != 1 ? 's' : ''} `;
        },
        cleanUp: function (value, prefix = false) {
            if (typeof value === 'string') {
                value = value.replaceAll(/(\s|\/|\.|&| )/gi, '');
            }
            return (prefix ? prefix + '-' : '') + value;
        },
        openForm: function (formName) {
            db().fetchForm(formName)
                .then((data) => {
                    if ('type' in data && data.type === 'error') {
                        toast().show(data.message, 'danger');
                    } else {
                        const modal = new bootstrap.Modal(appForm);
                        let form = appForm.querySelector('form');
                        let formBtn = appForm.querySelector('.form-button');
                        let formTitle = appForm.querySelector('.app-form-title');
                        let formWrap = appForm.querySelector('.app-form-wrap');

                        formTitle.innerHTML = formName;
                        formWrap.innerHTML = '';
                        forms(formWrap).create(data);

                        function submitAppForm(e) {
                            e.preventDefault();
                            e.stopPropagation();

                            let formName = appForm.querySelector('.app-form-title').innerHTML;

                            if (form.checkValidity()) {
                                document.body.classList.add('app-block');
                                toast().show('Enviando Formulario', 'warning');

                                fetch(settings.googleRestUrl('saveForm') + '&name=' + formName + '&code=' + settings.token + '&type=' + settings.userType + '&' + forms(form).serialize())
                                    .then((response) => {
                                        return response.json();
                                    })
                                    .then((data) => {
                                        document.body.classList.remove('app-block');
                                        toast().show(data.message, data.type);

                                        if ('type' in data && data.type === 'success') {
                                            modal.hide();
                                        }
                                    })
                                    .catch(function (message) {
                                        document.body.classList.remove('app-block');
                                        toast().show(message, 'warning');
                                    });
                            }

                            form.classList.add('was-validated');
                        };

                        function clearAppForm() {
                            let formElement = this.querySelector('form');
                            let btnElement = this.querySelector('.form-button');

                            formElement.removeEventListener('submit', submitAppForm, true);
                            formElement.classList.remove('was-validated');
                            btnElement.removeEventListener('click', submitAppForm, true);

                            this.querySelector('.app-form-wrap').innerHTML = '';
                            this.removeEventListener('hidden.bs.modal', clearAppForm);
                        };

                        appForm.addEventListener('hidden.bs.modal', clearAppForm);
                        form.addEventListener('submit', submitAppForm, true);
                        formBtn.addEventListener('click', submitAppForm, true);

                        modal.show();
                    }
                });
        },
        loadClients: function (clients) {
            let that = this;
            itemList.innerHTML = '';
            filtersWrap.classList.add('d-none');

            if (clients.length > 0) {
                for (let c = 0; c < clients.length; c++) {
                    let client = clients[c];
                    let itemClient = itemNode.content.cloneNode(true);
                    let titleClient = itemClient.querySelector('.app-list-title');
                    let bodyProduct = itemClient.querySelector('.app-list-body');
                    const clientFullName = client[0] + ' ' + client[1];;

                    titleClient.innerHTML = clientFullName;
                    bodyProduct.innerHTML = '';

                    // Client Modal
                    itemClient.firstElementChild.addEventListener('click', function (e) {
                        if (e.target.classList.contains('no-modal')) return false;

                        const modal = new bootstrap.Modal(appClient);
                        let clientTitle = appClient.querySelector('.client-title');
                        let clientDetails = appClient.querySelector('.client-details');
                        let clientWhatsApp = appClient.querySelector('.btn-whatsapp');

                        clientTitle.innerHTML = clientFullName;
                        clientDetails.innerHTML = `
                            <div class="d-flex flex-column">
                                <div><b class="me-1">CELULAR</b> ${client[3]}</div>
                                <div><b class="me-1">EMAIL</b> ${client[4]}</div>
                                <div><b class="me-1">LOCALIDAD</b> ${client[5]}</div>
                                <div><b class="me-1">CENTRO</b> ${client[6]}</div>
                            </div>
                        `;

                        if (client[3]) {
                            let clientNumber = client[3].replace(/[+()\s-]/g, '');
                            clientWhatsApp.removeAttribute('disabled');
                            clientWhatsApp.addEventListener('click', function () {
                                clientNumber = (clientNumber.startsWith(54) ? '' : 54) + clientNumber;
                                var waWindow = window.open("https://api.whatsapp.com/send?phone=" + clientNumber, '_system', 'location=yes');
                                setTimeout(() => {
                                    appClient.focus();
                                    if (waWindow) waWindow.close();
                                }, 3000);

                            }, true);

                        } else {
                            clientWhatsApp.setAttribute('disabled', true);
                        }

                        modal.show();
                    }, true);

                    itemList.appendChild(itemClient);
                }
            }

        },
        getShareObject: function (currentPage, order) {
            new Promise((resolve, reject) => {
                shareObject = [];
                order = parseInt(order) - 1;
                resolve('adasdas');

                localforage.getItem(currentPage).then(function (products) {
                    if (order in products.data) {
                        const row = products.data[order];

                        shareObject.push(`*PRODUCTO*\t${row[f.title]}`);
                        shareObject.push(`*MARCA*\t\t${row[f.brand]}`);
                        shareObject.push(`*PRECIO*\t\t_Condición ${row[f.condition]}_`);
                        /*
                        if (Object.values(productSharePrices).length > 0) {
                            Object.values(productSharePrices).forEach(function (price, i) {
                                shareObject.push(i % 2 == 0 ? `${price}` : `*${price}*`);
                            });
                            shareObject.push(`\n_Presupuestado el ${moment().format('DD-MM-YYYY')}_`);
                        }*/
                        resolve(shareObject);
                    } else {
                        resolve(shareObject);
                    }
                });
            });
        }
    };
}

export { page };