import { settings } from './settings.js';
import { toast } from './toast.js';

import localforage from 'localforage/dist/localforage.min.js';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

var db = function () {
    return {
        getUser: function () {
            return new Promise((resolve, reject) => {
                localforage.getItem('User')
                    .then(function (user) {
                        settings.token = user.token;
                        if (user.type) {
                            settings.userType = user.type;
                        } else {
                            if (user.role == 'CLIENTE') {
                                settings.userType = 'client';
                                data.type = 'client';
                                localforage.setItem('User', data);
                            } else {
                                settings.userType = 'user';
                                data.type = 'user';
                                localforage.setItem('User', data);
                            }
                        }

                        resolve('token' in user ? user : false);
                    })
                    .catch(function (message) {
                        resolve(false);
                    });
            });
        },
        refreshUser: function (refresh = false) {
            return new Promise((resolve, reject) => {
                localforage.getItem('User')
                    .then(function (user) {
                        if (!user.hasOwnProperty('timestamp') || moment().diff(moment(user.timestamp), 'minutes', true) > 60 || refresh) {
                            if (!user.hasOwnProperty('type')) user.type = 'user';
                            
                            fetch(settings.googleRestUrl('refreshUser') + '&code=' + user.token + '&type=' + user.type)
                                .then((response) => {
                                    return response.json();
                                })
                                .then((data) => {
                                    if (data) {
                                        data.timestamp = moment().format();
                                        localforage.setItem('User', data);
                                        resolve(data);
                                    } else {
                                        resolve(false);
                                    }
                                })
                                .catch(function (message) {
                                    console.log('Error Refresh User: ' + message);
                                });
                        }
                    })
                    .catch(function (message) {
                        resolve(false);
                    });
            });
        },
        createToken: function (pin, userType = 'user') {
            return new Promise((resolve, reject) => {
                fetch(settings.googleRestUrl('login') + '&pin=' + pin + '&type=' + userType)
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if ('token' in data) {
                            data.timestamp = moment().format();
                            localforage.setItem('User', data);

                            resolve(true);
                        } else {
                            resolve(data);
                        }
                    })
                    .catch(function (message) {
                        toast().show(message, 'warning');
                    });

            });
        },
        logOut: function () {
            localforage.clear();
            document.querySelector('#app-login').classList.remove('d-none');
            document.querySelector('#app-pin-wrap').classList.remove('d-none');
        },
        fetchPage: function (name, refresh) {
            return new Promise((resolve, reject) => {
                localforage.getItem(name).then(function (table) {
                    let expired = table ? moment().diff(moment(table.timestamp), 'days', true) > 1 : true;

                    if (table && !refresh && !expired) {
                        resolve(table.data);
                    } else {
                        fetch(settings.googleRestUrl(name) + '&code=' + settings.token + '&type=' + settings.userType)
                            .then((response) => {
                                return response.json();
                            })
                            .then((data) => {
                                localforage.setItem(name, {
                                    timestamp: moment().format(),
                                    data: data,
                                });

                                resolve(data);
                            })
                            .catch(function (message) {
                                toast().show(message, 'warning');
                            });
                    }
                }).catch(function (message) {
                    console.log('Error Fetch Page: ' + message);
                });
            });
        },
        fetchForm: function (name) {
            return new Promise((resolve, reject) => {
                localforage.getItem(name).then(function (form) {
                    let expired = form ? moment().diff(moment(form.timestamp), 'days', true) > 1 : true;

                    if (form && !expired) {
                        resolve(form.data);
                    } else {
                        fetch(settings.googleRestUrl('getForm') + '&name=' + name + '&code=' + settings.token + '&type=' + settings.userType)
                            .then((response) => {
                                return response.json();
                            })
                            .then((data) => {
                                localforage.setItem(name, {
                                    timestamp: moment().format(),
                                    data: data,
                                });

                                resolve(data);
                            })
                            .catch(function (message) {
                                toast().show(message, 'warning');
                            });
                    }
                }).catch(function (message) {
                    console.log('Error Fetch Form: ' + message);
                });
            });
        }
    };
}

export { db };