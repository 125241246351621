import { db } from './db.js';
import { page } from './page.js';

var forms = function (target) {
    return {
        create: function (data) {
            if (data) {
                let fields = Object.keys(data);
                fields.forEach(function (field, i) {
                    let dataField = data[field];
                    let inputType = dataField.type;
                    let row = document.createElement('div');
                    let label = document.createElement('label');
                    let input = document.createElement('input');
                    let inputWrap = document.createElement('div');
                    let extraWrap = document.createElement('div');
                    const fieldName = page().cleanUp(field);

                    row.classList.add('row', 'mb-2');

                    label.classList.add('col-4', 'col-form-label');
                    label.setAttribute('for', 'field-' + fieldName)
                    label.innerHTML = field + (dataField.required ? '*' : '');
                    if (dataField.hint) label.innerHTML += `<div class="form-text">${dataField.hint}</div>`;

                    inputWrap.classList.add('col-8');
                    input.setAttribute('id', 'field-' + fieldName);
                    input.classList.add('form-control');

                    //fecha, hora, texto, tel, email, checkbox, numero, range, textlargo, VALUE_IN_LIST (select), automatico (USUARIO), moneda
                    switch (inputType) {
                        case 'textolargo':
                            input = document.createElement('textarea');
                            input.classList.add('form-control');
                            input.setAttribute('id', 'field-' + fieldName);
                            break;
                        case 'VALUE_IN_LIST':
                            input = document.createElement('select');
                            input.classList.remove('form-control');
                            input.classList.add('form-select');
                            input.setAttribute('id', 'field-' + fieldName);
                            const options = data[field].options;

                            if (options.length > 0) {
                                options.forEach((value) => {
                                    const option = document.createElement('option');
                                    option.value = value;
                                    option.textContent = value;
                                    input.appendChild(option);
                                });
                            }
                            break
                        case 'checkbox':
                            extraWrap.classList.add('form-check', 'form-switch');
                            input.classList.add('form-check-input');
                            input.classList.remove('form-control');
                            input.setAttribute('type', 'checkbox');
                            input.value = true;
                            extraWrap.appendChild(input);
                            break;
                        case 'texto':
                            input.setAttribute('type', 'text');
                            break;
                        case 'fecha':
                            input.setAttribute('type', 'date');
                            break;
                        case 'hora':
                            input.setAttribute('type', 'time');
                            break;
                        case 'tel':
                            input.setAttribute('type', 'tel');
                            break;
                        case 'email':
                            input.setAttribute('type', 'email');
                            break;
                        case 'numero':
                            input.setAttribute('type', 'number');
                            break;
                        case 'timer':
                            let spanInput = document.createElement('span');
                            spanInput.classList.add('input-group-text');
                            spanInput.innerHTML = 30;
                            extraWrap.classList.add('input-group', 'flex-nowrap');
                            input.setAttribute('type', 'range');
                            input.setAttribute('min', 0);
                            input.setAttribute('max', 60);
                            input.setAttribute('value', 30);
                            input.classList.remove('form-control');
                            input.classList.add('form-range', 'form-control', 'h-auto', 'px-2');
                            input.addEventListener('input', function () {
                                this.nextElementSibling.innerHTML = this.value;
                            });
                            extraWrap.appendChild(input);
                            extraWrap.appendChild(spanInput);
                            break;
                        case 'automatico':
                            input.setAttribute('type', 'hidden');
                            switch (field) {
                                case 'USUARIO':
                                    db().getUser()
                                        .then((user) => {
                                            input.value = user.name + ' ' + user.lastname;
                                        });
                                    break;
                            }
                            break;
                    }

                    input.name = fieldName;
                    if (dataField.required) {
                        input.setAttribute('required', true);
                    }

                    if (inputType === 'checkbox' || inputType === 'timer') {
                        row.appendChild(label);
                        inputWrap.appendChild(extraWrap);
                        row.appendChild(inputWrap);
                        target.appendChild(row);
                    } else if (inputType === 'automatico') {
                        target.appendChild(input);
                    } else {
                        row.appendChild(label);
                        inputWrap.appendChild(input);
                        row.appendChild(inputWrap);
                        target.appendChild(row);
                    }

                });
            }
        },
        serialize: function () {
            const formData = new FormData(target);
            const searchParams = new URLSearchParams();

            for (const pair of formData) {
                searchParams.append('fields[' + pair[0] + ']', pair[1]);
            }

            return searchParams.toString();
        }
    };
};

export { forms }